/* global connect */

import 'amazon-connect-streams'

const initState = {
  // Access the connect object from the global window object
  // connectObject: window.connect,
  connectObject: connect,
  agentObject: null,
  ARN: null,
  phoneNumber: '',
  outboundCallPhoneNumber: '',
  activeContactId: '',
  activeInteractionId: '',
  currentConnectionState: '',
  connectionClosedByAgent: false,
  isIncomingCall: false,
  isOutboundCall: false,
  isOutboundCallConnecting: false,
  outboundCallConnection: null,
  agentHistorySaved: false,
  incomingOrOutcomingContactInfo: {
    id: null,
    contact_endpoint: null,
    contact_id: null,
    contact_type: null,
    connection_state: null,
    connection_type: null,
    metadata: null,
    contact_group_id: null,
    date_time: null,
    interaction_id: null
  },
  isConnectedCall: false,
  isEndedCall: false,
  isCallRejected: false,
  isTaskRejected: false,
  isAcceptedCall: false,
  currentConnectedContact: {},
  isIncomingChat: false,
  isAcceptedChat: false,
  missedChat: false,
  missedCall: false,
  afterCallWork: false,
  isIncomingTask: false,
  isAcceptedTask: false,
  isConnectedTask: false,
  isEndedTask: null,
  isTicketReplySent: null,
  missedTask: false,
  afterTaskWork: false,
  isInAfterCallWork: false,
  isContactError: false,
  isContactEndDateSaved: false,
  callHistory: [],
  contactsHistory: [],
  groupedContactHistory: [],
  groupContactHistories: [],
  selectedCustomerContactId: null,
  selectedCustomerAttributes: {},
  agentStatus: false,
  closureCode: [],
  closureCodeDetail: null,
  contactGroupIdList: [],
  activeContactsList: [],
  // isAutoAcceptEnabled: false,
  isAgentAutoAcceptEnabled: false,
  currentContact: null,
  loadMoreSummaryData: false
}

/**
 * The reducer function that handles the Amazon connect related values in Redux store
 * @param {Object} state the state object
 * @param {Object} action the action object
 * @returns {Object}
 */
const connectReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_CONNECT':
      return {
        ...state,
        connectObject: action.payload
      }

    case 'SET_AGENT':
      localStorage.setItem('agentARN', action.payload._getData()?.configuration?.agentARN)

      return {
        ...state,
        agentObject: action.payload
      }

    case 'SET_ARN':
      return {
        ...state,
        ARN: action.payload
      }

    case 'SET_IS_INCOMING':
      return {
        ...state,
        isIncomingCall: action.payload
      }

    case 'SET_IS_OUTBOUND_CALL':
      return {
        ...state,
        isOutboundCall: action.payload
      }

    case 'SET_IS_OUTBOUND_CALL_CONNECTING':
      return {
        ...state,
        isOutboundCallConnecting: action.payload
      }

    case 'SET_OUTBOUND_CALL_CONNECTION':
      return {
        ...state,
        outboundCallConnection: action.payload
      }

    case 'SET_OUTBOUND_CALL_PHONE_NUMBER':
      return {
        ...state,
        outboundCallPhoneNumber: action.payload
      }

    case 'SET_INCOMING_CONTACT_INFO':
      return {
        ...state,
        incomingOrOutcomingContactInfo: {
          ...state.incomingOrOutcomingContactInfo,
          ...action.payload
        }
      }
    case 'SET_CURRENT_CONNECTED_CONTACT':
      return {
        ...state,
        currentConnectedContact: action.payload
      }

    case 'SET_IS_CALL_CONNECTED':
      return {
        ...state,
        isConnectedCall: action.payload
      }

    case 'SET_IS_CALL_ENDED':
      return {
        ...state,
        isEndedCall: action.payload
      }

    case 'SET_IS_CALL_REJECTED':
      return {
        ...state,
        isCallRejected: action.payload
      }

    case 'SET_IS_TASK_REJECTED':
      return {
        ...state,
        isTaskRejected: action.payload
      }

    case 'SET_IS_CHAT_INCOMING':
      return {
        ...state,
        isIncomingChat: action.payload
      }

    case 'SET_MISSED_CHAT':
      console.log('>>> Missed chat:', action.payload)
      return {
        ...state,
        missedChat: action.payload
      }

    case 'SET_IS_ACCEPTED':
      return {
        ...state,
        isAcceptedCall: action.payload
      }

    case 'SET_IS_CHAT_ACCEPTED':
      return {
        ...state,
        isAcceptedChat: action.payload
      }
    case 'SET_PHONE_NUMBER':
      return {
        ...state,
        phoneNumber: action.payload
      }
    case 'SET_ACTIVE_CONTACT_ID':
      return {
        ...state,
        activeContactId: action.payload
      }
    case 'SET_ACTIVE_INTERACTION_ID':
      return {
        ...state,
        activeInteractionId: action.payload
      }
    case 'SET_CURRENT_CONNECTION_STATE':
      return {
        ...state,
        currentConnectionState: action.payload
      }
    case 'SET_IS_CONTACT_ERROR':
      return {
        ...state,
        isContactError: action.payload
      }
    case 'SET_CONNECTION_CLOSED_BY_AGENT':
      return {
        ...state,
        connectionClosedByAgent: action.payload
      }
    case 'GET_PHONE_NUMBER':
      return state.phoneNumber
    case 'SET_CALL_HISTORY':
      return {
        ...state,
        callHistory: action.payload
      }
    case 'SET_CONTACTS_HISTORY':
      return {
        ...state,
        contactsHistory: action.payload
      }
    case 'SET_GROUPED_CONTACT_HISTORY':
      return {
        ...state,
        groupedContactHistory: action.payload
      }
    case 'SET_ACTIVE_CONTACTS_LIST':
      return {
        ...state,
        activeContactsList: action.payload
      }
    case 'SET_GROUP_CONTACT_HISTORIES':
      return {
        ...state,
        groupContactHistories: action.payload
      }
    case 'APPEND_GROUP_CONTACT_HISTORIES':
      return {
        ...state,
        groupContactHistories: [...action.payload, ...state.groupContactHistories] // Appends new data to the beginning of the list
      }
    case 'SET_SELECTED_CUSTOMER_CONTACT_ID':
      return {
        ...state,
        selectedCustomerContactId: action.payload
      }
    case 'SET_SELECTED_CUSTOMER_DETAILS':
      return {
        ...state,
        selectedCustomerAttributes: action.payload
      }
    case 'SET_AGENT_STATUS':
      return {
        ...state,
        agentStatus: action.payload
      }
    case 'SET_CLOSURE_CODE':
      return {
        ...state,
        closureCode: action.payload
      }
    case 'SET_CLOSURE_DETAIL':
      return {
        ...state,
        closureCodeDetail: action.payload
      }
    case 'SET_CONTACT_GROUP_ID_LIST':
      return {
        ...state,
        contactGroupIdList: action.payload
      }

    case 'SET_IS_TASK_INCOMING':
      return {
        ...state,
        isIncomingTask: action.payload
      }

    case 'SET_MISSED_TASK':
      console.log('>>> Missed task:', action.payload)
      return {
        ...state,
        missedTask: action.payload
      }

    case 'SET_MISSED_CALL':
      return {
        ...state,
        missedCall: action.payload
      }

    case 'SET_AFTER_TASK_WORK':
      return {
        ...state,
        afterTaskWork: action.payload
      }

    case 'SET_AFTER_CALL_WORK':
      return {
        ...state,
        afterCallWork: action.payload
      }

    case 'SET_IS_IN_AFTER_CALL_WORK':
      return {
        ...state,
        isInAfterCallWork: action.payload
      }

    case 'SET_IS_TASK_ACCEPTED':
      return {
        ...state,
        isAcceptedTask: action.payload
      }

    case 'SET_IS_TASK_CONNECTED':
      return {
        ...state,
        isConnectedTask: action.payload
      }

    case 'SET_IS_ENDED_TASK':
      return {
        ...state,
        isEndedTask: action.payload
      }

    case 'SET_IS_TICKET_REPLY_SENT':
      return {
        ...state,
        isTicketReplySent: action.payload
      }

    case 'SET_IS_CONTACT_END_DATE_SAVED':
      return {
        ...state,
        isContactEndDateSaved: action.payload
      }

    case 'SET_AGENT_HISTORY_SAVED':
      return {
        ...state,
        agentHistorySaved: action.payload
      }
    case 'SET_IS_AUTO_ACCEPT_ENABLED':
      return {
        ...state,
        isAutoAcceptEnabled: action.payload
      }
    case 'SET_IS_AGENT_AUTO_ACCEPT_ENABLED':
      return {
        ...state,
        isAgentAutoAcceptEnabled: action.payload
      }
    case 'SET_CURRENT_CONTACT':
      return {
        ...state,
        currentContact: action.payload
      }
    case 'LOAD_MORE_SUMMARY_DATA':
      return {
        ...state,
        loadMoreSummaryData: action.payload
      }
    default:
      return state
  }
}

export default connectReducer
